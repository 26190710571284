// @ts-check
import {Button, TextField} from "@mui/material";
import {StaticDatePicker} from "@mui/x-date-pickers";
import {DateTime} from "luxon";
import {func} from "prop-types";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import config from "../../../config/config.json";
import {DATE_FORMATS, DateContext} from "../../contexts/dates";
import {loadPrintOnCallAction, loadPrintOpDataAction} from "../../pages/day_view/day_view_actions";
import {selectPrintOnCall, selectPrintSurgeries} from "../../pages/day_view/day_view_selectors";
import {selectCurrentOrganizationId, selectCurrentTimezone, selectLanguage} from "../../redux/app_selectors";
import {selectLoadPrintDatesStatus, selectPrintDates} from "../../redux/print_selectors";
import {isResolved} from "../../redux/utils/status";
import {selectFeSettings, selectPrint} from "../fe_settings/fe_settings_selectors";
import {selectAllStandardNamesObject, selectPatientInfos} from "../private_data/private_data_selectors";
import {selectRoomInfos} from "../rooms/rooms_selectors";
import CustomPickerDayPrint from "./components/custom_picker_day_print";
import {
    checkNumberOfFutureDates,
    checkPrintAllowed,
    generateReport,
    isPrintableDatesTheSameYear,
    setupReporter,
    sortSurgeriesByLocationAndTime
} from "./helpers";
import useStyles from "./print_layer.styles";

const reportPath = "/reports";

/**
 * render PrintLayer component
 * @param {Object} props
 * @param {function} props.onClose
 * @return {React.ReactElement}
 */
export const PrintLayer = ({onClose}) => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();
    const {areSame, format, fromISO, now, plusDT, minusDT, endOf} = useContext(DateContext);
    const dispatch = useDispatch();

    // States
    /** @type [Array<DateTimeType>, Function] */
    const [dateSelected, setDateSelected] = useState([]); // array of DateTime object

    // Redux store
    const organizationId = useSelector(selectCurrentOrganizationId);
    const {onCall /** @type InfoParams */: onCallParams} = useSelector(selectPrint);
    const {
        participantCategoriesForHealthcareService,
        theme: {locations /** type {Object<string, LocationLabelAndOrder>} */: locationOrder}
    } = useSelector(selectFeSettings);
    const printSurgeries = useSelector(selectPrintSurgeries);
    const printOnCall = useSelector(selectPrintOnCall);
    /** @type Array<{id: String, name: String}> */
    const roomInfos = useSelector(selectRoomInfos);

    const practitionerNamesObject = useSelector(selectAllStandardNamesObject({type: "practitioner"}));
    const patientPrivateInfos = useSelector(selectPatientInfos);
    const timezone = useSelector(selectCurrentTimezone);
    const locale = useSelector(selectLanguage);
    const loadPrintDatesStatus = useSelector(selectLoadPrintDatesStatus);
    const printableDates = useSelector(selectPrintDates);

    useEffect(() => {
        /**
         * load the stimulsoft.reports.js script if this component is mounted
         * This is one of the optimization to improve the performance of the app
         */
        const script = document.createElement("script");
        script.src = "/stimulsoft/stimulsoft.reports.js";
        script.defer = true;
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const {MAX_PRINTABLE_DATE_PAST, MAX_NUMBER_OF_PRINTABLE_DATE_FUTURE} = config;

    const arePrintDataReady = () => {
        return dateSelected.every((selectedDate) => {
            const systemDate = format(selectedDate, DATE_FORMATS.SYSTEM_DATE);
            return printOnCall[systemDate] && printSurgeries[systemDate];
        });
    };

    const handlePrint = async () => {
        setupReporter({
            licenseKey: global.STIMULSOFT_LICENSE,
            reportPath
        });
        const reportingDates = [...dateSelected]
            .sort((a, b) => a.toMillis() - b.toMillis())
            .map((date) => ({date, systemDate: format(date, DATE_FORMATS.SYSTEM_DATE)}));
        for (const {date, systemDate} of reportingDates) {
            const note = printableDates.find(({date: printDate}) => areSame(date, fromISO(printDate), "day"))?.note || "";
            await generateReport({
                date,
                systemDate,
                surgeries: printSurgeries[systemDate]?.sort(sortSurgeriesByLocationAndTime(locationOrder)),
                onCall: printOnCall[systemDate],
                practitionerNamesObject,
                patientPrivateInfos,
                roomInfos,
                participantCategoriesForHealthcareService,
                reportPath,
                locale,
                timezone,
                dayNote: note
            });
        }
    };
    const handleReset = (e) => {
        e.stopPropagation();
        setDateSelected([]);
    };
    const handleClickCheck = (date) => {
        const endOfToday = endOf(now(), "day");
        // Check if the date is printable
        if (
            checkPrintAllowed(
                date,
                endOfToday,
                printableDates.map((printableDate) => fromISO(printableDate.date)),
                areSame
            )
        ) {
            // If the date was deselected
            if (dateSelected.find((selected) => areSame(selected, date, "day"))) {
                setDateSelected(dateSelected.filter((selected) => !areSame(selected, date, "day")));
            } else {
                const addedDateSelected = dateSelected.concat([date]);
                // Check if the future dates are within the max number of future dates.
                if (checkNumberOfFutureDates(addedDateSelected, endOfToday, MAX_NUMBER_OF_PRINTABLE_DATE_FUTURE)) {
                    dispatch(loadPrintOpDataAction(organizationId, format(date, DATE_FORMATS.SYSTEM_DATE)));
                    dispatch(loadPrintOnCallAction(organizationId, format(date, DATE_FORMATS.SYSTEM_DATE), onCallParams));
                    setDateSelected(addedDateSelected);
                }
            }
        }
    };
    printableDates.sort((a, b) => (fromISO(a.date) < fromISO(b.date) ? -1 : 0));
    return (
        <div className={cx(classes.root, classes.table)}>
            <div className={classes.title}>{t("PrintLayer.title")}</div>
            <div className={classes.content}>
                <span className={classes.text}>{t("PrintLayer.pleaseSelect")}</span>
                <StaticDatePicker
                    disableHighlightToday={true}
                    displayStaticWrapperAs="desktop"
                    maxDate={plusDT(now(), "month", 3)}
                    minDate={minusDT(now(), "day", MAX_PRINTABLE_DATE_PAST)}
                    renderDay={(day, selectedDays, pickersDayProps) => {
                        const isDateSelected = dateSelected.some((date) => areSame(date, day, "day"));
                        return (
                            <CustomPickerDayPrint
                                date={day}
                                isPrintAllowed={checkPrintAllowed(
                                    day,
                                    endOf(now(), "day"),
                                    printableDates.map((printableDate) => fromISO(printableDate.date)),
                                    areSame
                                )}
                                isSelected={Boolean(isDateSelected)}
                                key={format(day, DATE_FORMATS.DATE)}
                                pickersDayProps={pickersDayProps}
                            />
                        );
                    }}
                    renderInput={(params /** @param {TextFieldProps} params */) => (
                        // @ts-ignore couldn't solve error if TextField props
                        <TextField variant="standard" {...params} className={classes.staticDatePicker} />
                    )}
                    value={dateSelected}
                    views={
                        isPrintableDatesTheSameYear(
                            endOf(now(), "day"),
                            areSame,
                            DateTime.max(...printableDates.map(({date}) => fromISO(date)))
                        )
                            ? ["month", "day"]
                            : ["year", "month", "day"]
                    }
                    onChange={handleClickCheck}
                />
                <Button
                    className={classes.resetButton}
                    color="primary"
                    disabled={dateSelected.length === 0}
                    variant="text"
                    onClick={handleReset}
                >
                    {t("PrintLayer.reset")}
                </Button>
            </div>
            <div className={classes.footer}>
                <Button color="primary" data-testid="printCancelButton" id="cancelPdf" variant="outlined" onClick={() => onClose()}>
                    {t("PrintLayer.cancel")}
                </Button>
                <Button
                    color="primary"
                    data-testid="printButton"
                    disabled={dateSelected.length === 0 || !isResolved(loadPrintDatesStatus) || !arePrintDataReady()}
                    id="savePdf"
                    variant="contained"
                    onClick={handlePrint}
                >
                    {t("PrintLayer.print")}
                </Button>
            </div>
        </div>
    );
};

PrintLayer.propTypes = {
    onClose: func.isRequired
};

export default PrintLayer;
